.container {
  align-items: center;
  background-color: #e9e9e9;
  background: url("../../resource/png/bg.jpg");
  /* background: url("https://res.cloudinary.com/dci1eujqw/image/upload/v1616769558/Codepen/waldemar-brandt-aThdSdgx0YM-unsplash_cnq4sb.jpg"); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  height: 100vh;
  place-items: center;
  border: none;
}

.copyright {
  position: absolute;
  bottom: 5%;
  right: 0;
  left: 0;
  color: #fff !important;
}

.ahover:hover {
  color: #0BC069;
}