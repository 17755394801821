.remark {
  margin-bottom: 10px;
  padding-bottom: 5px;
  color: rgb(82, 78, 78);
  border-bottom: 1px dashed rgb(179, 179, 179);
}

.box {
  padding-top: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.box::-webkit-scrollbar {
  display: none;
}

.title {
  width: 700px;

}

.menu {
  padding: 2px;
  margin-right: 10px;
  display: inline-block;
  width: 130px;
  text-align: center;
  color: rgb(240, 15, 15);
  border: 2px solid rgb(240, 15, 15);
  border-radius: 5px;
}

.select {
  color: #fff;
  background-color: rgb(240, 15, 15);
}