
.bar-styles{
    width: 32%;
    height: 145px;
    border-radius: 5px;
    border: 1px solid transparent;
    
    position: relative;
}
.img-style{
    width: 35px;
    height: 35px;
    position: relative;
    top: -45px;
    left: 10px;
}
.num-style{
    margin-left: 34px;
}
.type-style{
    position: absolute;
    right: 78px;
    /* font-size: 17px; */
    border-radius: 5px;
    padding: 5px 25px;
    cursor: pointer;
}
.finance-type-style{
    color: #E86459;
    background-color: #f7d0ce;
}
.discount-type-style{
    color: #65D59F;
    background-color: #d2f2e3;
}
.voucher-type-style{
    color: #FBB34D;
    background-color: #fde8cc;
}
.line-style{
    width: 100%;
    height: 2px;
    margin-top: 17px;
    border-radius: 5px;
    transition: 0.4s;
}


.finance-back-color{
    background-color: #E86459;
}
.discount-back-color{
    background-color: #65D59F;
}
.voucher-back-color{
    background-color: #FBB34D;
}
.triangle-style-finance{
    width: 0;
    height: 0;    
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 20px solid #E86459;

    position: relative;
    top: calc(50% + 8px);
    left: 68px;
    animation: slide-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.triangle-style-discount{
    width: 0;
    height: 0;    
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 20px solid #65D59F;

    position: relative;
    top: calc(50% + 8px);
    left: 68px;
    animation: slide-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.triangle-style-voucher{
    width: 0;
    height: 0;    
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 20px solid #FBB34D;

    position: relative;
    top: calc(50% + 8px);
    left: 68px;
    animation: slide-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


.inline{
    display: inline;
    cursor: pointer;
}
.width-50{
    width:50px;
    text-align: center;
}

@keyframes slide-bottom {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  