.trd {
  width: 100px !important;
  /* height: 30px; */
  /* line-height: 30px; */
  text-align: center;
  border: 1px solid #aaa;
}

.content {
  width: 110px;
  height: 110px;
  padding: 3px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  position: relative;
}

.pic-icon {
  position: relative;
  top: 86px;
  width: 98px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 2px;
  cursor: pointer;
}

.pic2 {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}