.title-style {
  font-size: 20px;
  font-weight: 600;
}

.title-tips-style {
  font-size: 15px;
  color: #BBBBBB;
  margin-left: 10px;
}

.add-button {
  border: 1px solid #0BC069;
  background-color: #d7f3e5;
  color: #0BC069;
  padding: 5px 0;
  text-align: center;
  width: 175px;
  border-radius: 5px;
  margin-top: 10px;
  line-height: 100%;
}

.add-button:hover {
  color: white;
  background-color: #0BC069;
  transition: 0.5s;
}

.preservation {
  border: 1px solid #0BC069;
  background-color: #d7f3e5;
  color: #0BC069;
  padding: 5px 0;
  text-align: center;
  width: 125px;
  border-radius: 5px;
  margin-top: 10px;
  line-height: 100%;
  cursor: pointer;
}

.preservation:hover {
  color: white;
  background-color: #0BC069;
  transition: 0.5s;
}


.remarks {
  color: red;
  font-size: 10px;
}

.video-box {
  margin: 5px 10px;
  padding: 6px 10px;
  border: 1px dashed #ddd;
  border-radius: 5px;
}

.content-video {
  position: relative;
  padding: 5px;
  width: 250px;
  height: 150px;
  /* margin-bottom: 60px; */
  border: 1px solid #ccc;
  border-radius: 10px;
}

.content-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.add-button {
  margin-left: 120px;
  width: 160px;
  height: 30px;
  line-height: 20px;
  padding: 5px 0;
  background-color: #0BC069;
  color: white;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
  transition: 0.5s;
  cursor: pointer;
}

.add-button:hover {
  background-color: #0BC069;
  opacity: 1;
}

.pic-div {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  overflow: hidden;
}

.content {
  width: 110px;
  height: 110px;
  padding: 3px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  position: relative;
}

.content-div {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  transition: 0.5s;
}

.pic {
  width: 100px;
  height: 100px;
  /* margin-top: -15px; */
  border-radius: 8px;
}

.pic:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.pic-icon-E {
  font-size: 20px;
  display: none;
  /* color: red; */
  padding: 0 5px;
  transition: 0.8s;
  position: absolute;
  top: 40px;
  left: 35px;
}

.content-div:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.pic-icon {
  position: relative;
  top: 88px;
  width: 100px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 2px;
  cursor: pointer;
}

.content-video {
  position: relative;
  padding: 5px;
  width: 250px;
  height: 150px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.content-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video {
  padding: 3px 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  border: 1px solid #0BC069;
  color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
}

.video-select {
  background-color: #0BC069;
  color: #fff;
}

.video-box {
  margin: 0 10px 10px;
  padding: 6px 10px;
  border: 1px dashed #ddd;
  border-radius: 5px;
}