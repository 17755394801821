.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-l {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-r {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-sa {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.row-w {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col-t {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.col-b {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.col-sa {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.col-sb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.vertical-lt {
  align-items: flex-start;
}

.vertical-rb {
  align-items: flex-end;
}

.hidden {
  display: none;
}

.tc {
  text-align: center;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.h100 {
  height: 100%;
}

.bg-c000 {
  background-color: #000;
}

.bg-cfff {
  background-color: #fff;
}

.c000 {
  color: #000;
}

.c333 {
  color: #333;
}

.c666 {
  color: #666;
}

.c999 {
  color: #999;
}

.cccc {
  color: #cccc;
}

.cfff {
  color: #fff;
}

.fz10 {
  font-size: 10px;
}

.fz11 {
  font-size: 11px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz14 {
  font-size: 14px;
}

.fz15 {
  font-size: 15px;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz18 {
  font-size: 18px;
}

.fz20 {
  font-size: 20px;
}

.fz22 {
  font-size: 22px;
}

.fz24 {
  font-size: 24px;
}

.fz26 {
  font-size: 26px;
}

.fz28 {
  font-size: 28px;
}

.fz30 {
  font-size: 30px;
}

.fz40 {
  font-size: 40px;
}

.fz60 {
  font-size: 60px;
}

.content-padding {
  padding: 10px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.ellipsis-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
  ;
}

.cursor-pointer {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.font-underline {
  text-decoration: underline
}

.none-select {
  user-select: none;
}

.ant-select-arrow {
  /* color: white; */
  color: #b8b8b8;
}

.bar {
  background-color: white;
  padding: 20px;
  font-size: 14px;
}

.bar-item {
  margin-right: 12px;
  padding: 1px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.bar-item-color {
  background-color: #404B6B;
  color: #fff;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: white;
}

.margin-r10 {
  margin-right: 10px;
}

.margin-r5 {
  margin-right: 5px;
}

.margin-r20 {
  margin-right: 20px;
}

.margin-r140 {
  margin-right: 140px;
}

.margin-r40 {
  margin-right: 40px;
}

.margin-b10 {
  margin-bottom: 10px;
}

.margin-b5 {
  margin-bottom: 5px;
}

.margin-b20 {
  margin-bottom: 20px;
}

.margin-b105 {
  margin-bottom: 105px;
}

.margin-l10 {
  margin-left: 10px;
}

.margin-l5 {
  margin-left: 5px;
}

.margin-l20 {
  margin-left: 20px;
}

.margin-l40 {
  margin-left: 40px;
}

.margin-l70 {
  margin-left: 70px;
}

.margin-t10 {
  margin-top: 10px;
}

.margin-t5 {
  margin-top: 5px;
}

.margin-t20 {
  margin-top: 20px;
}

.margin-t40 {
  margin-top: 40px;
}

.margin-t100 {
  margin-top: 100px;
}




.padding0 {
  padding: 0 !important;
}

.padding5 {
  padding: 5px;
}

.padding10 {
  padding: 10px;
}

.padding20 {
  padding: 20px;
}

.padding-b10 {
  padding-bottom: 10px;
}

.padding-t10 {
  padding-top: 10px;
}

.padding-tb10 {
  padding: 10px 0;
}

.padding-tb20 {
  padding: 20px 0;
}

.padding-lr10 {
  padding: 0 10px;
}

.padding-lr20 {
  padding: 0 20px;
}

.img_size {
  margin: 19px 0 19px 33px;
  width: 64px;
  height: 64px;
}

.w1550 {
  width: 1550px;
}

.w750 {
  width: 750px;
}

.w900 {
  width: 900px;
}

.w700 {
  width: 700px;
}

.w590 {
  width: 590px;
}

.w600 {
  width: 600px;
}

.w640 {
  width: 640px;
}

.w500 {
  width: 500px;
}

.w550 {
  width: 550px;
}

.w295 {
  width: 295px;
}

.w400 {
  width: 400px;
}

.w300 {
  width: 300px;
}

.w270 {
  width: 270px;
}

.mw250 {
  max-width: 250px;
}

.w200 {
  width: 200px;
}

.w198 {
  width: 198px;
}

.w180 {
  width: 180px;
}

.w150 {
  width: 150px;
}

.w110 {
  width: 110px;
}

.w90 {
  width: 90px;
}

.w80 {
  width: 80px;
}

.w70 {
  width: 70px;
}

.w60 {
  width: 60px;
}



.h500 {
  height: 500px;
}

.h400 {
  height: 400px;
}

.h200 {
  height: 200px;
}

.mh160 {
  max-height: 160px;
}

.h54 {
  height: 54px !important;
}

.h40 {
  height: 40px !important;
}

.h34 {
  height: 34px !important;
}

.relative {
  position: relative;
}

.borb {
  border-bottom: 1px solid#ddd;
}

.bord2 {
  border: 1px solid #1890ff;
}

.border5 {
  border-radius: 5px;
}

.bold {
  font-weight: bold;
}

.title {
  margin-top: -280px;
  margin-bottom: 260px;
}

.title2 {
  margin-top: -30px;
  margin-bottom: 60px;
}

.border-t2 {
  border-top: 2px dashed #aaa;
}

.border-r5 {
  border-radius: 5px;
}

.border1 {
  border: 1px solid #aaa;
}

.over {
  overflow: auto;
}

.ant-radio-inner:after {
  background-color: #0BC069;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #0BC069;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: #0BC069;
}

.btn-style {
  background-color: #0BC069;
  color: white;
  border-color: #fff;
  border-radius: 5px;
  text-align: center;
  opacity: 1;
  transition: 0.5s;
  cursor: pointer;
}

.btn-style:hover {
  background-color: #0BC069;
  opacity: 0.7;
  border-color: #fff;
}

/* 超出1行省略 */
.oneline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 超出2行省略 */
.twoline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/* 超出3行省略 */
.threeline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}