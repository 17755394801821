.bar {
  background-color: white;
  padding: 20px;
  cursor: pointer;
  font-size: 14px;
}

.bar_title {
  margin-right: 10px;
}

.button_div {
  margin-left: 20px;
}

.choiceType_div {
  width: 140px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  margin-left: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(187, 187, 187, 100);
  border-radius: 5px;
}

.choiceType_Color {
  background-color: #0BC069;
  color: white;
}

.columns1-id-span {
  color: #1890FF;
  font-size: 16px;
  text-decoration: underline;
}

.columns-span {
  color: rgba(142, 141, 141, 100);
  font-size: 14px;
}

.columns1-date-span {
  color: rgba(142, 141, 141, 100);
  font-size: 14px;
  opacity: 0.5;
}

.columns2-remark-span {
  color: rgba(142, 141, 141, 100);
  font-size: 14px;
  opacity: 0.7;
}

.columns2-copy-button {
  width: 140px;
  /* padding: 5px 0px; */
  background-color: #d7f3e5;
  color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid #0BC069;
  transition: 0.5s;
}

.columns2-copy-button:hover {
  background-color: #0BC069;
  color: white;
}

.columns2-reminder-button {
  width: 140px;
  /* padding: 5px 0px; */
  background-color: #f3d7d7;
  color: red;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid red;
  transition: 0.5s;
}

.columns2-reminder-button:hover {
  background-color: rgb(252, 24, 24);
  color: white;
}

.columns3-type-span {
  color: red;
  font-size: 14px;
}

.abnormal_div {
  background-color: #FFE1E3;
  color: red;
  width: 105px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  margin-top: 5px;
}

.columns-border {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 0;
  width: calc(100% + 16px);
  margin: 0 -8px;
  height: 145px;
  position: relative;
}

.columns-border:last-child {
  border-bottom: none;
}

.remack-overflow {
  overflow: hidden;
  overflow-y: auto;
}

.remack-overflow-width {
  height: 80px;
  overflow: hidden;
  overflow-y: auto;
}

.style-button {
  border-radius: 5px;
  color: white;
  opacity: 0.7;
  padding: 2px 4px;
  cursor: pointer;
  margin-bottom: 2px;
  margin-right: 2px;
}

.style-button:hover {
  opacity: 1;
}

.button-color1 {
  background-color: #F99200;
}

.button-color2 {
  background-color: #F91D00;
}

.button-color3 {
  background-color: #1890FF;
}

.button-color4 {
  background-color: #0BC069;
}

.title-down-style {
  width: 100%;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 15px;
  margin-top: 10px;
}

.hide {
  display: none;
}

.xiao-san-jiao {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #f7f7f7;
  position: absolute;
  /* top: 10px; */
  left: 170px;
  top: 200px;
}

.left-top-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  /* background: linear-gradient(135deg, #F99200, #F99200 50%, transparent 50%, transparent 100%); */
}

.columns-border:first-child .left-top-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -8px;
  left: 0;
  /* background: linear-gradient(135deg, #F99200, #F99200 50%, transparent 50%, transparent 100%); */
}

.left-top-type-span {
  color: white;
  transform: rotate(-45deg);
  margin-right: 15px;
  margin-top: 4px;
}


.urgeOrder-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background: linear-gradient(135deg, red, red 50%, transparent 50%, transparent 100%);
  animation: identifier 1s infinite;
  /* transition: 0.5s; */
}

.columns-border:first-child .urgeOrder-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -8px;
  left: 0;
  background: linear-gradient(135deg, red, red 50%, transparent 50%, transparent 100%);
  animation: identifier 1s infinite;
  /* transition: 0.5s; */
}

.inline {
  display: inline;
  cursor: pointer;
}

.width-50 {
  width: 50px;
  text-align: center;
}


.top-right-empty {
  border: 1px solid #0BC069;
  color: #0BC069;
  width: 185px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #D4F4E4;
  line-height: 30px;
}

.top-right-empty:hover {
  background-color: #0BC069;
  opacity: 1;
  color: white;
}

@keyframes identifier {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.2;
  }

  40% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}


.group-div {
  width: 65px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(187, 187, 187, 100);
  border-radius: 5px;
}

.group-div-color {
  background-color: #0BC069;
  color: white;
}

.vertical-line {
  border-left: 1px solid #f0f0f0;
  height: 120px;
  margin: 0 5px;
}

.delete-style:hover {
  color: red;
}

.delete-style {
  font-size: 20px;
}

.options0 {
  width: 1px;
  height: 50px;
  background-color: #BBBBBB;
  transform: rotateZ(30deg);
}

.title-order-cost {
  width: 100px;
  text-align: right;
  margin-right: 10px;
}

.w465 {
  width: 465px;
}

.modals_style {
  border-radius: 5px;
  background-color: #e1e1e1;
  padding: 5px 15px;
  margin-right: 10px;
  cursor: pointer;
}

.modals_choice_style {
  border-radius: 5px;
  background-color: #0BC069;
  color: white;
  padding: 5px 15px;
  margin-right: 10px;
  cursor: pointer;
}

.order_details_style {
  font-size: 16px;
  margin-right: 40px;
}

.display_hide {
  /* width: 1100px; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.room_div_style {
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.room_div_style:hover {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.room_type_div {
  border: 1px solid #0BC069;
  border-radius: 3px;
  padding: 2px 10px;
  background-color: #0BC069;
  color: white;
  opacity: 0.7;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 10px;
}

.room_type_div:hover {
  opacity: 1;
}




.coupon {
  width: 398px;
  border-radius: 5px;
  border: 1px solid #BBBBBB;
  color: #BBBBBB;
  text-align: center;
  cursor: pointer;
  padding: 5px;
}


.urgeOrder-type-right {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  background: linear-gradient(225deg, #e40b20, #e40b20 50%, transparent 50%, transparent 100%);
}

.columns-border:first-child .urgeOrder-type-right {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -12px;
  right: 0;
  background: linear-gradient(225deg, #e40b20, #e40b20 50%, transparent 50%, transparent 100%);
}

.right-top-type-span {
  color: white;
  transform: rotate(45deg);
  margin-left: 15px;
  margin-top: 4px;
}

.picBox {
  width: 100px;
  height: 100px;
  border: 1px solid #BBBBBB;
  border-radius: 5px;
}

.quick-remark {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #eee;
  cursor: pointer;
}

.tong {
  position: absolute;
  right: 88px;
  bottom: -20px;
  width: 70px;
  height: 70px;
  opacity: 0.8;
}

.tong2 {
  position: absolute;
  right: 55px;
  bottom: -35px;
  width: 70px;
  height: 70px;
  opacity: 0.8;
}


.content {
  width: 110px;
  height: 110px;
  padding: 3px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  position: relative;
}

.content-div {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 90px;
  transition: 0.5s;
}

.content-div2 {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  transition: 0.5s;
}

.content-div2:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div2:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.pic {
  width: 100px;
  height: 100px;
  margin-top: -15px;
  border-radius: 8px;
}

.pic2 {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.pic:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.pic2:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.pic-icon-E {
  font-size: 20px;
  display: none;
  /* color: red; */
  padding: 0 5px;
  transition: 0.8s;
  position: absolute;
  top: 40px;
  left: 35px;
}

.pic-div {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  overflow: hidden;
}

.content-div:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.pic-icon {
  position: relative;
  top: 86px;
  width: 98px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 2px;
  cursor: pointer;
}

.content-video {
  position: relative;
  padding: 5px;
  width: 250px;
  height: 150px;
  margin-left: 8px;
  /* margin-bottom: 60px; */
  border: 1px solid #ccc;
  border-radius: 10px;
}

.content-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video {
  padding: 3px 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  border: 1px solid #0BC069;
  color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
}

.video-select {
  background-color: #0BC069;
  color: #fff;
}

.video-box {
  margin: 0 10px 10px;
  padding: 6px 10px;
  border: 1px dashed #ddd;
  border-radius: 5px;
}