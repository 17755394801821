.back-color0 {
  background-color: #fff;
}

.back-color1 {
  background-color: #4B4B6B;
}

.back-color2 {
  background-color: #FAFAFA;
}

.back-color4 {
  background-color: #201d37;
}

.back-color5 {
  background-color: #f5222d;
}

.back-color6 {
  background-color: #0BC069;
}

.back-color7 {
  background-color: #1890FF;
}



.background1 {
  background: linear-gradient(135deg, #1890FF, #1890FF 50%, transparent 50%, transparent 100%);
}

.background2 {
  background: linear-gradient(135deg, #f3a75e, #f3a75e 50%, transparent 50%, transparent 100%);
}

.color0 {
  color: #fff !important;
}

.color1 {
  color: #4B4B6B;
}

.color2 {
  color: #f5222d;
}

.color3 {
  color: #1890ff;
}

.color4 {
  color: #201d37;
}

.color5 {
  color: #BBBBBB;
}

.color6 {
  color: #0BC069;
}

.color7 {
  color: #11e27d;
}

.color8 {
  color: #aaa;
}

.color9 {
  color: #f3a75e;
}

.color10 {
  color: rgb(68, 68, 68);
}