.content {
  width: 110px;
  height: 110px;
  padding: 3px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  position: relative;
}

.content-div {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 90px;
  transition: 0.5s;
}

.content-div2 {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  transition: 0.5s;
}

.content-div2:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div2:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.pic {
  width: 100px;
  height: 100px;
  margin-top: -15px;
  border-radius: 8px;
}

.pic2 {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.pic:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.pic2:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.pic-icon {
  position: relative;
  top: 86px;
  width: 98px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 2px;
  cursor: pointer;
}

.pic-icon-E {
  font-size: 20px;
  display: none;
  /* color: red; */
  padding: 0 5px;
  transition: 0.8s;
  position: absolute;
  top: 40px;
  left: 35px;
}

.pic-div {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  overflow: hidden;
}

.content-div:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.room_div_style {
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.room_div_style:hover {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.room_type_div {
  border: 1px solid #0BC069;
  border-radius: 3px;
  padding: 2px 10px;
  background-color: #0BC069;
  color: white;
  opacity: 0.7;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 10px;
}

.room_type_div:hover {
  opacity: 1;
}

.group-div {
  width: 70px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(187, 187, 187, 100);
  border-radius: 5px;
}

.group-div-color {
  background-color: #0BC069;
  color: white;
}

.vertical-line {
  border-left: 1px solid #f0f0f0;
  height: 120px;
  margin: 0 5px;
}

.options0 {
  width: 1px;
  height: 50px;
  background-color: #BBBBBB;
  transform: rotateZ(30deg);
}

.top-right-empty {
  border: 1px solid #0BC069;
  color: #0BC069;
  width: 185px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #D4F4E4;
  line-height: 30px;
}

.top-right-empty:hover {
  background-color: #0BC069;
  opacity: 1;
  color: white;
}

.delete-style:hover {
  color: red;
}

.delete-style {
  font-size: 20px;
}

.footer {
  position: fixed;
  bottom: 0;
  padding: 10px 50px;
  width: 100%;
  height: 150px;
  background-color: rgba(248, 248, 248, 0.897);
}