
.content{
    width: 100%;
    height: 88vh;
}
.serach{
    position: absolute;
    margin: 20px;
    z-index: 2;
    width: calc(100% - 40px);
    height: 300px;
    background-color: white;
    opacity: 0.9;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.21);
    border-radius: 5px;
}
.div_textArea{
    width: 600px;
}
.div_address_title{
    font-size: 20px;
    font-weight: 600;
    margin-right: 10px;
}
.div_address_explain{
    color: #838383;
    font-size: 16px;
}


.query_button{
    background-color: #0BC069;
    color: white;
    width: 216px;
    padding: 5px 0;
    border-radius: 5px;
    text-align: center;
    transition: 0.5s;
    cursor: pointer;

    margin-left: 70px;
}

.hasWorker{
    color: red;
    font-size: 20px;
    margin-left: 20px;
}