.box {
  width: 600px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.between {
  width: 30px;
  height: 32px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 18px;
  user-select: none;
}