.download-style{
    width: 185px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(187, 187, 187, 100);
    border-radius: 5px;
    margin-right: 5px;

}

.download-color{
    background-color: #0BC069;
    color: white;
}


.inline{
    display: inline;
    cursor: pointer;
}
.width-50{
    width:50px;
    text-align: center;
}
.style-button3{
    border-radius: 5px;
    color: white;
    background-color: #1890FF;
    opacity: 0.7;
    padding: 5px 15px;
    margin-bottom: 2px;
    margin-right: 2px;
    cursor: pointer;
}
.style-button3{
    opacity: 1;
}