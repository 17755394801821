.product {
  margin-right: 33px;
  width: 272px;
  height: 405px;
  border: 1px solid #ccc;
}

.like {
  height: 40px;
  border-top: 1px solid #ccc;
}

.collect-box {
  margin-right: 20px;
  width: 202px;
  height: 306px;
  border: 1px solid #ccc;
}