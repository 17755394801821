.wi60 {
  margin-top: 8%;
  width: 60%;
}

.border {
  margin-top: 10px;
  height: 160px;
  /* height: 10%; */
  border: 2px solid #eee;
  border-radius: 5px;
}

.borTop {
  padding: 0 40px;
  width: 100%;
  height: 100px;
  background-color: rgba(226, 248, 165, 0.658);
  border-bottom: 2px solid #eee;
  font-family: 'Times New Roman', Times, serif;
}

.borbottom {
  padding: 0 90px;
  width: 100%;
  height: 60px;
  font-family: 'Times New Roman', Times, serif;
}

.success {
  width: 50px;
  height: 50px;
}