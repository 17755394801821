/* Google Fonts Import Link */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap'); */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #2F4050;
  z-index: 100;
  transition: all 0.5s ease;
  /* overflow-y: auto */
}

.close {
  width: 88px;
}

.logo-details {
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
}

.logo-details i {
  height: 50px;
  line-height: 50px;
}

.logo_name {
  margin-top: 20px;
  font-size: 22px;
  color: #0BC069;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}

.close .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

/* .sidebar .nav-links{
    height: 100%;
    padding: 0 0 150px 0;
    overflow: auto;
  } */
/* .sidebar.close .nav-links{
    overflow: visible;
  } */
/* .sidebar .nav-links::-webkit-scrollbar{
    display: none;
  } */
.nav-links li {
  position: relative;
  list-style: none;
  /* transition: all 0.4s ease; */
}

.nav-links li:hover {
  background: #293846;
  cursor: pointer;
}

.left-li-style {
  background: #293846;
  border-left: 3px solid #42b983;
}

.iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close .nav-links li .iocn-link {
  display: block
}

.nav-links li i {
  height: 50px;
  min-width: 88px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar .nav-links li div div {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .nav-links li div div .link_name {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  width: 72px;
}

.sidebar.close .nav-links li div div .link_name {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #293846;
  display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}

.sidebar .nav-links li .sub-menu div {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu div:hover {
  opacity: 1;
}

.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}

.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .nav-links li div div .bxs-chevron-down {
  color: white;
  margin-left: 50px;
}

.sidebar.close .nav-links li div div .bxs-chevron-down {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.home-section {
  position: relative;
  background: #E4E9F7;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  /* position: fixed; */

  /* overflow: auto; */
  /* display: none; */
  overflow-y: auto;
  overflow-x: hidden;
}

.link_name1 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  /* width: 260px; */
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 88px;
  word-break: keep-all;
}

.link_name1:hover {
  color: #0BC069;
}

.sidebar.close .nav-links li div div .link_name1 {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.sidebar.close~.home-section {
  left: 88px;
  width: calc(100% - 88px);
}

.home-section .home-content {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #2F4050;
  font-size: 35px;
}

.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}

.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

/* .text{
    min-width: 420px;
} */


.rotateChange>div {
  width: 30px;
  height: 5px;
  background-color: #42b983;
  transition: all .2s linear;
  margin: 0 10px;
}

.rotateChange>div:nth-of-type(2) {
  margin-top: 4px;
}

.rotateChange>div:nth-of-type(3) {
  margin-top: 4px;
}

.rotateChange1>div {
  width: 30px;
  height: 6px;
  background-color: #42b983;
  transition: all .2s linear;
  margin: 0 10px;
}

.rotateChange1 div:nth-of-type(1) {
  background-color: #eb596c;
  transform: rotate(45deg);
}

.rotateChange1 div:nth-of-type(2) {
  background-color: #eb596c;
  transform: scaleX(0);
}

.rotateChange1 div:nth-of-type(3) {
  margin-top: -12px;
  background-color: #eb596c;
  transform: rotate(-45deg);
}

.home-children {
  margin: 120px 20px 20px 20px;
}

.home-children1 {
  margin: 100px 0px 0px 0px;
}

.navigation-tag {
  width: 100%;
  height: 40px;
  border-bottom: 2px solid #42b983;
  border-top: 2px solid #E4E9F7;
  background-color: #ffffff;
}

.tag-right {
  height: 36px;
  padding: 0 30px;
  line-height: 36px;
  border-right: 2px solid #E4E9F7;
  border-left: 2px solid #E4E9F7;
  margin: 0 -1px;
  cursor: pointer;
}

.tag-div {
  height: 36px;
  line-height: 36px;
  overflow: hidden;
}

.div-icon-color :hover {
  color: #1890ff;
  cursor: pointer;
}

.tag-right-color {
  background-color: #0BC069;
  color: #fff;
}

.home-top {
  position: fixed;
  width: calc(100% - 260px);
  z-index: 2;
  transition: all 0.5s ease;
}

.sidebar.close~.home-section .home-top {
  position: fixed;
  width: calc(100% - 88px);
  z-index: 2;
  transition: all 0.5s ease;
}

.home-content,
.navigation-tag {
  min-width: 1660px;
}

.home-content-rigth {
  /* margin-left: 600px; */
  transition: 0.5s;
}

.sidebar.close~.home-section .home-top .home-content .home-content-rigth {
  margin-left: 772px;
  transition: 0.5s;
}

.home-content-button {
  padding: 5px 20px;
  background-color: #F99200;
  border-radius: 5px;
  text-align: center;
  color: white;
  cursor: pointer;
}